::webkit-scrollbar {
  display: none;
}

html {
  height: 100%;
}

#body {
  /* background-color: #FFE5D0; */
  height: 100%;
  margin: 0;
  /* background-color: whitesmoke; */
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(
    rgba(197, 217, 255, 1) 34%,
    rgba(94, 151, 170, 1) 100%
  ); */
}

.truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}

.job-item {
  display: grid;
  grid-template-columns: 4px 1fr;
}

.scroll-component {
  overflow-y: scroll;
}

.mission-list {
  height: 85vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-width: 0;
}

.mission-list::-webkit-scrollbar {
  display: none;
}

.span-success {
  background-color: #479f76;
}
.span-warning {
  background-color: #ffcd39;
}

.CardStatusSuccess {
  background-color: #479f76;
  width: "100%";
  height: "100%";
}

.CardStatusWaiting {
  background-color: #ffcd39;
}

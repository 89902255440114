.cardCanvas {
  position: relative;
  height: 300px;
}

.sigCanvas {
  width: 100%;
  height: 100%;
}

.imgContainer {
  height: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* .imgContainer img {
  object-fit: contain;
  object-position: center;
} */
